// External Dependencies
import React, { Component } from 'react';
import isEmail from 'validator/lib/isEmail';

// Internal Dependencies
import {
  PrivacyPolicy,
  TermsOfService,
} from './shared/Links';

// Component Definition
// This component is not used any more
class ContactForm extends Component {
  // eslint-disable-next-line react/state-in-constructor
  state = {
    email: '',
    emailTouched: false,
    name: '',
    nameTouched: false,
    privacyPolicyChecked: false,
    school: '',
    schoolTouched: false,
  }

  handleBlur = (e) => {
    this.setState({ [`${e.target.name}Touched`]: true });
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleCheck = (e) => {
    this.setState({ [e.target.name]: e.target.checked });
  }

  render() {
    const {
      email,
      emailTouched,
      name,
      nameTouched,
      privacyPolicyChecked,
      school,
      schoolTouched,
    } = this.state;

    const emailPresent = email.trim().length > 0;
    const emailValid = isEmail(email);
    const nameValid = name.trim().length > 0;
    const schoolValid = school.trim().length > 0;

    let emailErrorMessage = null;
    if (!emailPresent) {
      emailErrorMessage = 'Required';
    } else if (!emailValid) {
      emailErrorMessage = 'Please enter a valid email';
    }

    const nameErrorMessage = !nameValid && 'Required';
    const schoolErrorMessage = !schoolValid && 'Required';

    const emailError = emailTouched && !!emailErrorMessage;
    const nameError = nameTouched && !!nameErrorMessage;
    const schoolError = schoolTouched && !!schoolErrorMessage;

    const formValid = emailValid && nameValid && schoolValid && privacyPolicyChecked;

    return (
      <div>
        <form
          action="/mailing_list_success"
          data-netlify-honeypot="bot-field"
          data-netlify="true"
          method="post"
          name="contact"
        >
          <input type="hidden" name="form-name" value="contact" />

          <p hidden>
            <label htmlFor="hidden">
              Don’t fill this out:{' '}
              <input
                name="bot-field"
                onChange={(e) => this.handleChange(e)}
              />
            </label>
          </p>

          <label htmlFor="name">
            Full Name
            <input
              className={nameError ? 'input-error' : ''}
              id="name"
              name="name"
              onBlur={this.handleBlur}
              onChange={this.handleChange}
              type="text"
              value={name}
            />
            {nameError && (
            <p className="error">{nameErrorMessage}</p>
            )}
          </label>

          <label htmlFor="email">
            Email
            <input
              className={emailError ? 'input-error' : ''}
              id="email"
              name="email"
              onBlur={this.handleBlur}
              onChange={this.handleChange}
              type="text"
              value={email}
            />
            {emailError && (
            <p className="error">{emailErrorMessage}</p>
            )}
          </label>

          <label htmlFor="school">
            School
            <input
              className={schoolError ? 'input-error' : ''}
              id="school"
              name="school"
              onBlur={this.handleBlur}
              onChange={this.handleChange}
              type="text"
              value={school}
            />
            {schoolError && (
            <p className="error">{schoolErrorMessage}</p>
            )}
          </label>

          <input
            checked={privacyPolicyChecked}
            id="privacy-policy"
            name="privacyPolicyChecked"
            onChange={this.handleCheck}
            type="checkbox"
            value={privacyPolicyChecked}
          />
          <label htmlFor="privacy-policy">
            I have read and acknowledge Presto&apos;s <TermsOfService /> and <PrivacyPolicy />.
          </label>

          <div className="button-wrapper">
            <button
              className="button special-2"
              disabled={!formValid}
              type="submit"
            >
              SUBMIT
            </button>
          </div>
        </form>
      </div>
    );
  }
}

export default ContactForm;
