// External Dependencies
import React from 'react';

// Internal Dependencies
import HeaderGeneric from '../components/HeaderGeneric';
import Layout from '../components/layout';
import Meta from '../components/Meta';
import SignUpForm from '../components/SignUpForm';

// Component definition
const SignUp = () => (
  <Layout>
    <Meta title="Get Updates" />
    <HeaderGeneric
      subtitle="Join our mailing list to receive updates as we near our launch!"
      title="Get Updates"
    />
    <div id="main" className="form">
      <section id="content" className="main">
        <SignUpForm />
      </section>
    </div>
  </Layout>
);

export default SignUp;
